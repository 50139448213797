@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Inter';
    src: local('Inter'), url('./fonts/Inter-VariableFont_slnt\,wght.ttf');
}

@layer base {
    body {
        @apply flex flex-col h-screen;
    }
    #root {
        @apply flex flex-col flex-1 bg-gray-200;
    }
    main {
        @apply overflow-hidden bg-transparent;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}
 
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    overflow:hidden;
    border-bottom-right-radius:4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: hsl(0, 0%, 72%);
    border-radius: 2px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2;
}

@media print {
    main {
        max-width: unset !important;
        margin: 0 !important;
    }
}